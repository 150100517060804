import styles from './ProductTiles.module.scss';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProductThumbnail } from 'components/primitives/product';
import { routesBuilder } from 'routes';
import { Link, SanaLinkButton } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { Placeholder } from 'components/primitives/placeholders';

const ProductGroupTile = ({ productGroup, noImage, tilesPerView }) => {
  const ref = useRef(null);
  const { id, url, title, defaultImage: image, productCount } = productGroup;
  const getRouteData = routesBuilder.forProductGroup(id);
  const [countText] = useSanaTexts(['ProductGroup_Count']).texts;
  const canOrder = useHasAbilities(AbilityTo.OrderProducts)[0];

  useEffect(() => {
    if (!tilesPerView)
      return;

    ref.current.style.width = 100 / tilesPerView + '%';

    return () => ref.current.style.width = '';
  }, [tilesPerView]);

  const placeholder = <Placeholder className="placeholder" />;

  return (
    <article className={styles.productTile} ref={ref} aria-label={`${title} (${id})`}>
      <div className={styles.tileTop}>
        <div className={styles.productImg}>
          <Link draggable="false" url={url} to={getRouteData}>
            <ProductThumbnail draggable="false" src={image && image.small} noImageSrc={noImage} title={title} />
          </Link>
        </div>
        <div className={styles.productDescription}>
          <Link url={url} to={getRouteData} draggable="false" className={styles.productTitle}>
            {title}
          </Link>
          <div className={styles.productGroupCountText}>
            {makeSimpleText(countText, [productCount])}
          </div>
        </div>
      </div>
      <div className={styles.tileBottom}>
        {canOrder &&
          <SanaLinkButton size="sm" className={styles.btnProductGroup}
            url={url}
            to={getRouteData}
            textKey="SelectVariants"
            placeholder={placeholder}
          />
        }
      </div>
    </article>
  );
};

ProductGroupTile.propTypes = {
  productGroup: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    defaultImage: PropTypes.shape({
      small: PropTypes.string,
    }),
    productCount: PropTypes.number,
  }).isRequired,
  noImage: PropTypes.string,
  tilesPerView: PropTypes.number,
};

export default ProductGroupTile;
